import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import owlbear from "./owlbear.jpg";
import kenku from "./kenku.jpg";

function App() {
  return (
    <Container maxWidth="sm">
      <Stack alignItems="center" my={4} gap={2}>
        <Typography variant="h4">Mini Manta Studio</Typography>
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={owlbear}
              alt="owlbear rodeo"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Owlbear Rodeo
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tabletop map sharing for Owlbear lovers
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" href="https://www.owlbear.rodeo" fullWidth>
                Learn More
              </Button>
            </CardActions>
          </Card>
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={kenku}
              alt="kneku fm"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Kenku FM
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Online tabletop audio sharing for Discord
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" href="https://www.kenku.fm" fullWidth>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}

export default App;
